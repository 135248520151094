

.thumbnailCard{
    box-shadow: var(--box-shadow) !important;
    border: none;
    border-radius: 10px !important;
    .cardContent{
        // height: 25vh;
        // padding: 0 0.8vw;
        font-size: 1em;
        color: var(--secondary-color);
        .cardHeader{
            font-size: 1.4vw;
            font-weight: 600;
            color: var(--primary-color-dark-edaan);
            letter-spacing: 1px;
            width: 100%;
            padding: 1vw 0;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media only screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
        .cardCategory{
            font-size: 0.9em;
            font-weight: 600;
            width: fit-content;
            background: var(--border-color);
            color: var(--primary-color-light-edaan);
            letter-spacing: 1px;
            padding: 4px 10px;
            border-radius: 5px;
            margin: 0.7vw 0;
            @media only screen and (max-width: 768px) {
                font-size: 12px;
                margin: 5px 0;
            }
        }
        .cardDescription{
            // font-size: 1vw;
            font-weight: 500;
            color: var(--primary-color-dark-edaan);
            padding: 0.5vw 0;
            @media only screen and (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
    &:hover{
        box-shadow: var(--box-shadow-selected) !important;
        transition: all 0.3s ease !important;
    }
}
// .cardfooter{
//     font-size: 0.9em;
//     padding: 1vw 0.5vw 0 0.5vw;
//     color: var(--secondary-color);
//     justify-content: flex-end;
//     width: 100%;
    // .owner{
    //     margin-right: -1.5vw;
    //     padding: 5px 1.5vw 5px 1vw;
    //     border-top-left-radius: 10px;
    //     border-bottom-left-radius: 10px;
    //     background: var(--primary-color-light-edaan);
    //     color: var(--primary-color-dark-edaan);
    //     letter-spacing: 1px;
    //     cursor: pointer;
    //     span{ 
    //         color: white;
    //     }
    //     &:hover{
    //         opacity: 0.8;
    //     }
    // }
// }