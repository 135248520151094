.chevron{
    position: absolute;
    color: white;
    font-size: 60px;
    top: 50%;
    cursor: pointer;
}
.chevron-right{
    right: 5px;
}
.chevron-left{
    left: 5px;
}