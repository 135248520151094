@import url('../../../index.scss');

.termsDialogBox {
    min-width: 50%;
    padding: 1.5vw;

    @media only screen and (max-width: 576px) {
        padding: 10px;
        width: 95%;
    }

    @media only screen and (min-width: 576px) and (max-width: 1024px) {
        padding: 15px;
        width: 80%;
    }

    .dialog-header {
        // color: var(--primary-color-light-edaan);
        // color: #03c473;
        font-weight: 600;
    }

    .divider {
        height: 0.02vw;
        width: 100%;
        background-color: #03c473;
        margin: 5px 0;
    }

    .dialog-body {
        max-height: 70vh;
        overflow: auto;
        justify-content: stretch;
        text-align: justify;
        color: black;
        display: flex;
        flex-direction: column;

        p {
            font-size: 14px;
        }

        .meta-part {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            padding: 10px;
            // border: 1px solid #eee;

            .meta-name {
                // font-size: 12px;
                padding: 5px 0;
                font-weight: 500;
            }

            .meta-para {
                font-size: 10px;

            }
        }

        .bullets {
            font-size: 11px;
            padding: 5px 0 5px 15px;

            li::before {
                display: inline-block;
                width: 1em;
                margin-left: -1em
            }
        }
    }
}