.pujoCard {
    border: none;
    background-color: white !important;
    border-radius: 10px !important;
    margin: 0 5px;
    .cardHeader {
      font-weight: 500;
      background: linear-gradient(transparent, black);
      color: #fff;
      letter-spacing: 1px;
      width: 100%;
      padding: 1vw;
      .cardName{
        //font-size: 1.2vw;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .categoryDiv{
        display: flex;
        justify-content: space-between;
      }
      .cardCategory {
        font-size: 13px;
        color: #fff;
        background: rgba(0, 0, 0, 0.664);
        padding: 2px 5px;
        border-radius: 5px;
        width: fit-content;
        margin-bottom:0.5vw;
      }
      .cardMore {
        font-size: 13px;
        color: #fff;
        background: rgba(0, 0, 0, 0.664);
        padding: 2px 5px;
        border-radius: 5px;
        width: fit-content;
        margin-bottom:0.5vw;
        &:hover{
          cursor: pointer;
        }
      }
      .fundInfoWrap{
        //font-size: 0.8vw;
        font-size: 12px;
        background: rgba(255, 255, 255, 0.651);
        //padding: 0.5vw;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
  
  