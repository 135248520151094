
.contributor{
    width: 100%;
    font-size: 1em;
    color: var(--primary-color-dark-edaan);
    padding: 1em 1.5em;
    letter-spacing: 1.5px;
    border-bottom: 1px solid var(--border-color);
    justify-content: left;
    .amount{
       color: var(--primary-color-light-edaan);
    }
    .profileIcon{
        width: 20%;
        padding-right: 10px;
        img{
            border-radius:50px;
            width: 2vw;
            height: 2vw;
        }
    }
    .donorId{
        width: 80%;
        flex-direction:column;
        justify-content: center;
    }
}