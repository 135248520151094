.styledSnackbar{
    position: fixed;
    z-index: 5500;
    top: 16px;
    right: 16px;
    max-width: 70vw;
    min-width: 300px;
    .snackbarContent{
        display: flex;
        gap: 8px;
        overflow: hidden;
        background-color:#ffe227;
        border-radius: 8px;
        border: 1px solid '#d0d7de';
        box-shadow: 0 2px 16px #d0d7de;
        padding: 0.75rem;
        color: #003A75;
        font-family: IBM Plex Sans, sans-serif;
        font-weight: 500;
        text-align: start;
        position: relative;
      
        & .snackbar-message {
          flex: 1 1 0%;
          max-width: 100%;
          color:#ffffff;
        }
      
        & .snackbar-title {
          margin: 0;
          line-height: 1.5rem;
          margin-right: 0.5rem;
          text-transform: uppercase
        }
      
        & .snackbar-description {
          margin: 0;
          line-height: 1.5rem;
          font-weight: 400;
          // text-transform: capitalize
        }
      
        & .snackbar-close-icon {
          cursor: pointer;
          flex-shrink: 0;
          padding: 2px;
          border-radius: 4px;
          color:#32383f;
      
          &:hover {
            background: #f6f8fa;
          }
        }
    }
    .errorDialog{
        background: #ff0000 !important;
    }
    .successDialog{
        background: #008000 !important;
    }
}
