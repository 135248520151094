.card {
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 10px !important;

  .cardMedia {
    height: 25vh;
    position: relative;
  }

  .pendingCover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.466);
  }

  .pendingText {
    color: white;
    left: 1vw;
    bottom: 1vw;
  }

  .donationText {
    top: 0;
    left: 0;
    width: 100%;
    background: rgb(206, 2, 2);
    color: #fff;
    padding: 7px;
    text-align: center;
    text-transform: capitalize;
    //font-size: 0.8vw;
    font-size: 12px;
  }

  .cardInfoIcon {
    font-size: 1.2vw;
    right: 1vw;
    top: 1vw;
    border-radius: 50%;
  }

  .cardHeader {
    //font-size: 1.5vw;
    font-size: 22px;
    font-weight: 500;
    position: absolute;
    background: linear-gradient(transparent, black);
    color: #fff;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 1.5px;
    bottom: 0;
    left: 0;
    padding: 1.2vw;
    width: 100%;

    .cardCategory {
      font-size: 13px;
      color: #fff;
      padding: 2px 5px;
      border-radius: 5px;

      .closedTab {
        font-size: 10px;
        background: white;
        font-weight: normal;
        margin-left: 5px;
        padding: 2px 5px;
        border-radius: 3px;
      }
    }

    @media only screen and (max-width: 576px) {
      font-size: 18px;
    }
  }

  .cardContent {
    // height: 25vh;
    font-size: 1em;
    color: var(--secondary-color);

    .cardDescription {
      font-size: 12px;
      padding: 1.5vh 0;
      height: 10vh;
    }

    .cardMore {
      display: flex;
      justify-content: space-between;
    }

    .dates {
      // text-align: center;
      //font-size: 0.8vw;
      font-size: 12px;

      b {
        color: var(--primary-color-dark-edaan);
      }
    }

    .more {
      font-size: 12px;
      color: var(--primary-color-light-edaan);
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    @media only screen and (max-width: 576px) {
      font-size: 14px;
    }
  }

  .cardNameLink {
    text-decoration: none;
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--primary-color-light-edaan);
  }

  .cardfooter {
    font-size: 0.9em;
    padding: 7px 0 0;
    color: var(--secondary-color);
    justify-content: center;
    width: 100%;

    .owner {
      margin: 0 2px;
      padding: 5px;
      border-radius: 10px;
      letter-spacing: 1px;
      color: black;
      text-align: center;
      max-width: calc(100% - 4px);
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: white;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &:hover {
    box-shadow: var(--box-shadow-selected);
    transition: all 0.3s ease !important;
  }
}

.closedCard {
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
  }
}