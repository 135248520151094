.payment_page{
    background: var(--border-color);
    min-height: 100vh;
    overflow:auto;
    .appHeader{
        height: 20vh;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .homegovLogo{
            height: 80px;
            cursor: pointer;
            img{
                height: 100%;
                border-radius: 10px;
            }
            @media only screen and (max-width: 576px) {
                height: 60px;
                // margin-right: 5px;
            }
        }
    }
    .formHeader{
        align-items: center;
        height: 15vh;
        position: relative;
        .backBtn{
            font-size: 13px;
            letter-spacing: 1px;
            color: red;
            background: #eee;
            padding: 5px 7px;
            border-radius: 5px;
            cursor: pointer;
            bottom: 0;
            left: 0;
        }
    }
    .paymentStatusContainer{
        padding: 0 10vw;
        margin: 0 10vw;
        background: var(--background-primary);
        height: 100vh;
        overflow: hidden;
        @media only screen and (max-width: 768px) {
            margin: 0 10px;
        }
    }
    .custom_form{
        padding: 15px 10px;
        border-radius: 10px;
        border: 1px solid gainsboro;
        .iconHeader{
            text-align: center;
            font-size: 5vw;
        }
    }
    .success{
        color:green
    }
    .error{
        color:red
    }
}

.paymentInfo{
    div {
        padding: 5px 0;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 11px;
    }
}
.paymentButton{
    padding: 5px 10px !important;
    @media only screen and (max-width: 768px) {
    }
}
.downloadReceiptBtn{
    padding: 5px 10px !important;
    font-size: 13px;
    color: #01882e;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 5px auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    &:hover{
        background: #eee;
    }
}