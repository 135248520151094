@import '~bootstrap/scss/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css?family=Coiny');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Open+Sans:wght@300;400;500;600&family=Ultra&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  font-family: "Poppins", sans-serif !important;
  --primary-color-light-edaan: #03c473;
  --primary-color-light-epujo: #f08c1a;
  --primary-color-dark-edaan: #01160d;
  --primary-color-dark-epujo: #751c06;
  --primary-color-dark-green-edaan: #032c1a;
  --secondary-color: #95aac0;
  --background-primary: #fff;
  --background-secondary-edaan: #fbfbfb;
  --background-secondary-epujo: #fffbed;
  --border-color: #eeeeee;
  --extra-color: #3d72e7;
  --box-shadow: 0 3px 15px rgb(0 0 0 / 13%);
  --box-shadow-selected: 0 3px 15px rgb(0 0 0 / 40%);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;

  @media only screen and (max-width: 768px) {
    width: 0;
    height: 0;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #eee;
  border: 3px solid #fbfbfb;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #fbfbfb;
  box-shadow: none;
}

input:focus-visible {
  outline: none;
}


.navgovLogo {
  height: 70px;
  margin-right: 10px;

  img {
    height: 100%;
    margin-right: 2em
  }
}

.navNxtLogo {
  height: 175px;
  // margin-right: 10px;

  img {
    height: 100%;
  }
}

.disabledItem {
  opacity: 0.5;
  pointer-events: none;
}

.text-center {
  text-align: center !important;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.paddingRL {
  padding-right: 7vw;
  padding-left: 7vw;

  @media only screen and (max-width: 768px) {
    padding-right: 3vw;
    padding-left: 3vw;
  }

  @media only screen and (max-width: 576px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.paddingTB {
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.inline {
  display: inline-block !important;
}

.maxHeight {
  height: 100% !important;
}

.maxWidth {
  width: 100% !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.displayNone {
  display: none;
  overflow: hidden;
}

.justify-content-right {
  justify-content: right !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-even {
  justify-content: space-evenly !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center !important;
}

.disabledPanel {
  opacity: 0.6;
  pointer-events: none;
}

.pullRight {
  float: right !important;
}

.pullCenter {
  margin-left: auto !important;
  margin-right: auto !important;
  width: fit-content;
}

.completed {
  color: #6ce399;
}

.processing,
.awaiting {
  color: #f5b05b;
}

.failed {
  color: #ed7546;
}

.reviewing {
  color: var(--extra-color);
}

.approved {
  color: #38c938;
}

.pending {
  color: #ee9e27;
}

.centerAlign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerAlignVertical {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.overflowAuto {
  overflow: auto;
}

.toggleOn {
  color: var(--primary-color-light-edaan);
  font-size: 2.5vw;
  cursor: pointer;
}

.toggleOff {
  color: var(--secondary-color);
  font-size: 2.5vw;
  cursor: pointer;
}

.text_ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.blankPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .blankPageContent {
    width: 400px;
    margin: 0px auto;
    text-align: center;

    .icon {
      font-size: 6vw;
      background: #eee;
      padding: 1vw;
      border-radius: 50%;
      margin-bottom: 1vw;
    }

    .msg {
      font-size: 1.5vw;
      font-weight: bold;
      letter-spacing: 1px;
      color: lightgrey;
    }

    .custommsg {
      font-size: 16px;
      letter-spacing: 1px;
      padding: 20px;
      color: lightgrey;
    }
  }
}





.creationForm {
  background: var(--border-color);
  height: 100%;

  @media only screen and (max-width: 768px) {
    background-color: var(--background-primary)
  }

  .formContainer {
    padding: 15px 10vw;
    margin: 0 10vw;
    background: var(--background-primary);
    height: 100%;
    overflow: auto;

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 15px 5vw;
    }

    @media only screen and (max-width: 768px) {
      padding: 15px 0;
    }

    .formHeader {
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;

      .govLogo {
        height: 100px;
        margin-bottom: 10px;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
          height: 100px;
        }

        img {
          height: 100%;
        }
      }

      .form_sitename {
        font-weight: 900;
        letter-spacing: 0.1rem;
        font-size: 22px;
        margin-bottom: 10px;
        cursor: pointer;

        @media only screen and (max-width: 576px) {
          font-size: 18px;
        }
      }



      .form_organiser_creation {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: var(--primary-color-dark-edaan) !important;
        //font-size: 1vw;
        font-size: 16px;
        text-transform: uppercase;

        @media only screen and (max-width: 576px) {
          font-size: 12px;
        }
      }
    }

    .backBtn {
      font-size: 13px;
      letter-spacing: 1px;
      color: red;
      background: #eee;
      padding: 5px 7px;
      border-radius: 5px;
      cursor: pointer;
      bottom: 10px;
      left: 0;
    }
  }
}







.logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}

.alertWrap {
  min-width: 15vw;
  width: fit-content;
  padding: 0.5vw 1vw;
  top: 1vw;
  right: 1vw;
  color: white;
  font-size: 0.8vw;
  background: grey;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  z-index: 10;

  .alertType {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .alertText {
    font-weight: bold;
  }

  .alertsubText {
    //font-size: 0.7vw;
    font-size: 12px;
  }
}

.errorBg {
  background-color: rgb(175, 7, 7) !important;
}

.successBg {
  background-color: rgb(3, 148, 3) !important;
}

.infoBg {
  background-color: rgb(6, 130, 212) !important;
}




.useroptionContainer {
  background: var(--background-primary);
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 1em;
  box-shadow: var(--box-shadow);
  width: fit-content;

  .useroption {
    //padding: 0.5vw 1.5vw;
    padding: 8px 20px;
    width: fit-content;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 768px) {
    height: fit-content;

    .useroption {
      padding: 6px 15px;
      font-size: 13px;
    }
  }
}

.formWrap {
  box-shadow: var(--box-shadow) !important;
  border: none;
  border-radius: 10px !important;
  // position: fixed;
  width: 100%;
  background: white;
}


.contentWrapper {
  height: calc(90vh - 50px);
  overflow: auto;

  .innerContentBtnWrap {
    height: 100%;
    flex-direction: column;

    .mobileDonateSection {
      display: none;

      @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;
        padding: 10px 0;
      }
    }
  }

  .innerContentWrap {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 92vh;

    .innerContentWrap {
      height: calc(100% - 65px);
      overflow: auto;
    }
  }
}


.headerWrap {
  flex-direction: column;
  justify-content: baseline;
}

.subheader {
  //font-size: 0.9vw;
  font-size: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-bottom: 0.2em;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
}

.edaanHeader {
  color: var(--primary-color-dark-edaan);
}

.epujoHeader {
  color: var(--primary-color-dark-epujo);
}

.subheaderInfo {
  //font-size: 0.8vw;
  font-size: 12px;
  color: var(--secondary-color);
  letter-spacing: 1px;

  @media only screen and (max-width: 576px) {
    font-size: 10px;
  }
}




.maxHeight {
  height: 100% !important;
}

.contentSection {
  height: 86%;
  margin: 0 -1vw;
}

.smallPane {
  width: 26%;

  // padding: 0 1vw;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.xxsmallPane {
  width: 15%;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.xxmediumPane {
  width: 71%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.fullWidthPane {
  width: 100%;
  padding: 0 1vw;
}

.largePane {
  width: 74%;
  padding: 0 1vw;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.sidePane {
  width: 24%;

  @media only screen and (max-width: 768px) {
    width: 33.3%;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.profileSidePane {
  width: 24%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.middlePane {
  width: 52%;

  @media only screen and (max-width: 768px) {
    width: 33.3%;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.oneThirdPane {
  width: 33.3%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.twoThirdPane {
  width: 66.6%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.widthHalf {
  width: 50%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.widgetGap {
  // padding-top: 1.5vw;
  // padding-bottom: 1vw;
  padding: 0.8vw;

  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }
}

.widgetWrapper {
  border: none;
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.chartWrapper {
  padding: 0.5vw 0;
}

.showAllFooter {
  font-size: 14px;
  font-weight: bold;
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: none;
  padding: 1em;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
}


.fundraiserCategoriesWrap {
  border-radius: 10px;
  background: var(--border-color);
  box-shadow: none !important;

  .categoryWrapper {
    letter-spacing: 1px;

    .categoryName {
      padding: 20px 10px 0;
      font-size: 1em;
      font-weight: bold;
      color: var(--primary-color-light-edaan);
    }
  }
}

.categoryBreakup {
  display: flex;
  flex-direction: column;

  .smallCard {
    width: calc(100% - 20px);
    font-size: 0.8em !important;
    color: var(--primary-color-dark-edaan);
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
    flex-direction: column;
    background: white;
    box-shadow: var(--box-shadow);
    margin: 5px 10px;
    border-radius: 10px;

    @media only screen and (max-width: 576px) {
      margin: 5px;
      width: calc(100% - 10px);
    }

    .cardHeader {
      //font-size: 0.9vw !important;
      font-size: 14px !important;
      padding: 5px 0;
      font-weight: bold;

      @media only screen and (max-width: 576px) {
        font-size: 12px !important;
      }
    }

    &:hover {
      box-shadow: var(--box-shadow-selected);
    }

    // .showExpenseBtn{
    //   color: #3d72e7;
    //   cursor: pointer;
    //   padding: 0.5vw 0;
    // }
  }
}

.edaanProfileNameWrap {
  background: linear-gradient(#03c47442, rgba(8, 8, 8, 0.637), rgba(8, 8, 8, 0.637));
  // box-shadow: linear-gradient(rgba(3, 196, 116, 0.2588235294), rgba(8, 8, 8, 0.637)) !important;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;

  .ngo {
    display: inline;
    text-transform: uppercase;
    color: var(--primary-color-dark-edaan);
    font-size: 3.5vw;
    background: white;
    font-family: "Monoton", Helvetica, sans-serif;
  }

  @supports (-webkit-background-clip: text) {
    .ngo {
      color: transparent;
      //   background: linear-gradient(7deg, var(--primary-color-light-edaan) 50%, var(--primary-color-dark-edaan) 0);
      background: linear-gradient(0deg, var(--primary-color-light-edaan) 26%, white 57%);
      -webkit-background-clip: text;
    }
  }

  // .category{
  //     border-top: 1px solid #fff;
  //     font-size: 1vw;
  //     color: white;
  //     letter-spacing: 2px;
  //     text-transform: uppercase;
  //     padding-top: 1vw;
  //     // width: fit-content;
  //     // margin: 0 auto;
  // }
}

.epujoProfileNameWrap {
  background: linear-gradient(#c4870342, rgba(8, 8, 8, 0.637), rgba(8, 8, 8, 0.637));
  // box-shadow: linear-gradient(rgba(3, 196, 116, 0.2588235294), rgba(8, 8, 8, 0.637)) !important;
  padding: 1vw 2vw;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;

  .ngo {
    display: inline;
    text-transform: uppercase;
    color: var(--primary-color-dark-epujo);
    font-size: 3.5vw;
    background: white;
    font-family: "Monoton", Helvetica, sans-serif;
  }

  @supports (-webkit-background-clip: text) {
    .ngo {
      color: transparent;
      //   background: linear-gradient(7deg, var(--primary-color-light-edaan) 50%, var(--primary-color-dark-edaan) 0);
      background: linear-gradient(0deg, var(--primary-color-light-epujo) 26%, white 57%);
      -webkit-background-clip: text;
    }
  }

  // .category{
  //     border-top: 1px solid #fff;
  //     font-size: 1vw;
  //     color: white;
  //     letter-spacing: 2px;
  //     text-transform: uppercase;
  //     padding-top: 1vw;
  //     // width: fit-content;
  //     // margin: 0 auto;
  // }
}




// *************************rjsf overrides**********************
.field .control-label {
  //font-size: 0.8vw;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.field-description {
  //font-size: 0.8vw;
  font-size: 12px;
  color: var(--secondary-color);
  margin: 0;
}

.field-object {
  //padding: 0.5vw 1vw !important;
  padding: 8px 16px !important;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 10px;

  .field {
    padding: 10px 0;

    input,
    select {
      height: 50px;
      //padding: 0 0.5vw;
      padding: 0 8px;
      border-radius: 5px;

      @media only screen and (max-width: 576px) {
        height: 34px;
      }

      &:focus {
        border: 1px solid var(--secondary-color);
        outline: none;
        box-shadow: none;
      }
    }

    input:focus-visible,
    select:focus-visible {
      outline: none;
    }

    textarea {
      padding: 0 0.5vw;
      border-radius: 5px;
      min-height: 4vw;

      &:focus {
        // border: 1px solid var(--secondary-color);
        outline: none;
        box-shadow: none;
      }
    }

    textarea:focus-visible {
      outline: none;
    }
  }
}

textarea {
  padding: 0.5vw;
  border-radius: 5px;

  &:focus {
    // border: 1px solid var(--secondary-color);
    outline: none;
    box-shadow: none;
  }
}

textarea:focus-visible {
  outline: none;
}

input {
  height: 50px;
  padding: 0 0.5vw;
  border-radius: 5px;
  border: 1px solid grey;

  &:focus {
    border: 1px solid var(--secondary-color);
    outline: none;
    box-shadow: none;
  }
}

input:focus-visible {
  outline: none;
}

.loginBtn {
  width: calc(100% - 2vw) !important;
  margin-left: 1vw !important;
}

.logoutBtn {
  margin-left: 0.5vw !important;
  letter-spacing: normal !important;
  text-transform: uppercase;
  color: #751c06;
  cursor: pointer;
}


.OTPWrap {
  width: 100%;
  padding: 2vw;
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background: white;
  z-index: 3;

  .otpText {
    font-size: 0.7vw;
    color: rgb(179, 4, 4);
  }

  .otpInputWrap {
    padding: 2vw 0;
    justify-content: center;

    .otpInput {
      border: 1px solid var(--border-color);
      border-radius: 5px;
      width: 50px;
      height: 50px;
      margin: 0 5px;
      outline: auto;
      text-align: center;
    }
  }
}


.imagesWrap {
  width: 100%;
  // column-count: 3;
  display: block;

  .imageInputWrap {
    margin: 0.5vw;
    display: inline-block;
    width: calc(33.3% - 1vw);
    height: 250px;
    border-radius: 10px;
    background: #eee;
    text-align: center;

    .image {
      height: 100%;
      width: auto;
    }

    .imageText {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-size: 1.2vw;
      padding: 1vw;
      background: rgba(8, 8, 8, 0.377);
      color: white;
      opacity: 0;
      border-radius: 10px;

      &:hover {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }
}


.cardFooter {
  width: 100%;
  justify-content: space-evenly;

  .footerBtn {
    // width: 50%;
    text-align: center;
    align-items: center;
    margin: 0;
    border-radius: 0;
    font-weight: bolder;
    justify-content: center;
  }
}

.donateBtn {
  color: red !important;
  font-size: 14px !important;
}

.hidden {
  display: none !important
}



.categoryBadge {
  padding: 7px 10px;
  //font-size: 0.9vw;
  font-size: 14px;
  background: var(--border-color);
  font-weight: bold;
  width: fit-content;
  margin: 0.5vw;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;

  .icon {
    font-size: 15px;
    padding: 0 10px;
    cursor: pointer;
  }

  @media only screen and (max-width: 576px) {
    padding: 5px 10px;
    font-size: 10px;
  }
}


.fundInfo {
  font-family: 'Open Sans', sans-serif;

  .subinfo {
    justify-content: space-between;
    font-size: 12px;

    b {
      font-size: 18px;

      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  .supporter {
    align-items: center;

    .icon {
      color: red;
    }
  }
}

.epujofundInfo {
  .subinfo {
    b {
      color: var(--primary-color-light-epujo);
    }
  }

  .progress-bar {
    background-color: var(--primary-color-light-epujo) !important;
  }

  .progress,
  .progress-stacked {
    height: 8px;
    background-color: #fce4c9;
  }
}

.edaanfundInfo {
  .subinfo {
    b {
      color: var(--primary-color-dark-edaan);
    }
  }

  .progress-bar {
    background-color: var(--primary-color-light-edaan) !important;
  }

  .progress,
  .progress-stacked {
    height: 8px;
    background-color: #c9fce7;
  }
}

.yearsWrap {
  padding-bottom: 1vw;

  .year {
    width: fit-content;
    //padding: 0.3vw 1vw;
    margin: 0 0.5vw 0.5vw 0;
    //font-size: 0.8vw;
    font-size: 12px;
    padding: 5px 24px;
    background: var(--background-primary);
    color: var(--primary-color-dark-epujo);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: var(--primary-color-light-epujo);
    }
  }

  .selectedYear {
    background: var(--primary-color-light-epujo);
    color: white;

    &:hover {
      color: white;
    }
  }
}

.redColor {
  color: red;
}

.upcomingLockIcon {
  color: red;
  font-size: 1.2vw;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

legend {
  @media only screen and (max-width: 576px) {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
    line-height: 1.3rem;
  }
}

.showOnMobile {
  display: none !important;

  @media only screen and (max-width: 1024px) {
    display: block !important;
  }
}

.hideOnMobile {
  display: block !important;

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }
}

.fullOnMobile {
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.mobileMenu {
  display: flex;

  #menu {
    z-index: 20;
  }

  #menu-bar {
    // width: 20px;
    // margin: 20px 7vw;
    // cursor: pointer;
    // position: absolute;
    // right: 0px;
    width: 70px;
    padding: 30px 25px;
    cursor: pointer;
    position: absolute;
    right: 0px;

    @media only screen and (max-width: 768px) {
      width: 70px;
      padding: 30px 25px;
    }
  }

  .bar {
    height: 3px;
    width: 100%;
    //background-color: #f08c1a;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }

  #bar1 {
    transform: translateY(-4px);
  }

  #bar3 {
    transform: translateY(4px);
  }

  .nav {
    margin-top: 60px;
    transition: 0.3s ease;
    display: none;

    ul {
      padding: 0 22px;

      li {
        list-style: none;
        padding: 2px 0;
        margin: 0;
        text-align: right;

        .menuItem {
          color: #a09f9f;
          font-size: 12px;
          text-decoration: none;
          letter-spacing: 0.2em;
          cursor: pointer;
          text-transform: uppercase;

          &:hover {
            font-weight: bold;
          }

          &.selMenuItem {
            color: white;
          }
        }

        .organiseButton {
          background: rgba(0, 0, 0, 0.397);
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          letter-spacing: 0.2em;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }

    .profileSection {
      padding-top: 50px;

      .profileName {
        font-size: 13px;
        opacity: 0.7;
      }

      .profiletype {
        font-size: 11px;
        opacity: 0.7;
      }
    }

    .login_out_btn {
      background: rgba(0, 0, 0, 0.397);
      font-size: 12px;
      color: rgb(253, 145, 145);
      padding: 10px;
      border-radius: 5px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .menu-bg,
  #menu {
    top: 0;
    right: 0;
    position: absolute;
  }

  .menu-bg {
    z-index: 19;
    transition: 0.5s width ease-in-out;

    &.change-bg {
      max-width: 400px;
      height: 100vh;
      width: 70%;
    }
  }

  .change {
    display: block;

    .bar {
      background-color: white;
    }

    #bar1 {
      transform: translateY(0px) rotateZ(-45deg);
    }

    #bar2 {
      opacity: 0;
    }

    #bar3 {
      transform: translateY(-6px) rotateZ(45deg);
    }

    &#menu-bar {
      transform: translate(0px, 3px);
    }
  }
}



.headerContainer {
  display: flex;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.autocompleteSearch {
  padding: inherit;
  width: 40vw;

  @media only screen and (max-width: 768px) {
    width: 70%;
  }

  .searchInputContainer {
    width: 100%;
    height: 50px;

    .searchInput {
      border: none;
      width: 100%;
      height: -webkit-fill-available;
    }
  }
}

.imageDialog {
  .imaegDialogcloseBtn {
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    z-index: 2;
    cursor: pointer;
  }

  .bgImg {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.footerText {
  background: #fbfbfb;
  width: 100%;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  flex-wrap: wrap;

  .link {

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  @media screen and (max-width: 576px) {
    margin: 0 auto;
    line-height: 18px;
  }
}

.commonHeader {
  height: 20vh;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .govLogo {
    height: 80px;

    // margin-right: 10px;
    img {
      height: 100%;
      border-radius: 10px;
    }

    @media only screen and (max-width: 576px) {
      height: 60px;
      // margin-right: 5px;
    }
  }

  .sitename {
    color: var(--background-primary);
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 2px;

    @media only screen and (max-width: 786px) {
      font-size: 18px;
    }

    @media only screen and (max-width: 576px) {
      font-size: 15px;
    }
  }
}

.backgroundWrap {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;

  .contentWrap {}
}