.card {
    box-shadow: var(--box-shadow);
    border: none;
    border-radius: 10px !important;
    min-height: 10vh;
    cursor: pointer;
    &:hover{
        .cardHeader{
            padding: 1.2vw 1.2vw 2vw;
            transition: all 0.5s ease;
        }
        box-shadow: var(--box-shadow-selected);
        transition: all 0.3s ease !important;
    }
}
.organiserDesc{
    //font-size: 0.9vw;
    font-size: 14px;
    color: var(--primary-color-dark-edaan);
    @media only screen and (max-width: 576px) {
        font-size: 12px;
    }
}
.cardFooter{
    bottom: 0;
    width: 100%;
    left: 0;
    background: var(--background-secondary-edaan);
    // align-items: center;
    // justify-content: start;
    // justify-content: space-evenly;
    font-size: 13px;
    color: var(--secondary-color);
    .footerInfo{
        padding: 7px 10px;
        justify-content: start;
        align-items: center;
        text-overflow: ellipsis;
        // width: 100%;
        width: 70%;
        .icon{
            font-size: 18px;
            color: red;
        }
        .address{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
        }
    }
    .moreInfo{
        display: flex;
        padding: 7px 10px;
        justify-content: end;
        color: var(--primary-color-light-edaan);
        width: 30%;
        &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
    }
    .amount{
        color: red;
    }
}