.donationDialogBox {
    min-width: 80%;
    padding: 1.5vw;
    overflow-y: hidden !important;
    @media only screen and (max-width: 1024px) {
        padding: 15px;
        min-width: 95%;
    }
    .dialogHeader {
        //font-size: 1vw;
        font-size: 16px;
        color: var(--secondary-color);
        // font-weight: 600;
        @media only screen and (max-width: 576px) {
            font-size: 12px;
        }
        .programHeader{
            flex-direction: column;
            .donateToContainer{
                align-items: baseline;
                @media only screen and (max-width: 576px) {
                    flex-direction: column;
                }
            }
            .campaignname{
                background: #eee;
                padding: 4px 8px;
                border-radius: 5px;
                font-size: 12px;
                width: fit-content;
                margin-top: 5px;
                @media only screen and (max-width: 576px) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .dialogBody{
        padding: 1vw 0;
        height: 70vh;
        .costCodesWrap{
            // font-size: 1vw;
            font-size: 16px;
            width: 100%;
            border: 1px solid #eee;
            border-radius: 10px;
            height: 100%;
            overflow: auto;
            @media only screen and (max-width: 576px) {
                font-size: 12px;
            }
            .costCodeRow{
                align-items: center;
                border-bottom: 1px solid #eee;
                padding: 10px;
                .headname{
                    font-weight: 600;
                    align-items: center;
                    width: 50%;
                    @media only screen and (max-width: 768px) {
                       width: 100%;
                    }
                    @media only screen and (max-width: 1024px) {
                        width: 40%;
                    }
                }
                .costAmounts{
                    padding: 10px;
                    align-items: center;
                    width: 50%;
                    justify-content: space-between;
                    @media only screen and (min-width: 768px) {
                        .mobileViewTargets{
                            display: none !important;
                        }
                    }
                    @media only screen and (max-width: 768px) {
                        width: 100%;
                        .webViewTargets{
                            display: none !important;
                        }
                        .mobileViewTargets{
                            b{
                                font-size: 10px;
                                padding: 0 0 0 10px; 
                            }
                        }
                    }
                    @media only screen and (max-width: 1024px) {
                        width: 60%;
                    }
                }
            }
            .donationReasonWrap{
                background: #fbfbfb;
                font-size: 12px;
                .reasonRow{
                    border-bottom: 1px solid #eee;
                    .reasonCol{
                        padding: 8px;
                    }
                    .largeCol{
                        width: 60%;
                    }
                    .smallCol{
                        width: 20%;
                    }
                }
            }
            .donationsTxnWrap{
                padding: 10px;
                background: #fbfbfb;
                border-bottom: 1px solid #eee;
                .txnTab{
                    background: #b5f5daf1;
                    // padding: 0px 8px;
                    padding-left: 8px;
                    margin: 5px;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .downloadBtn{
                        padding: 2px 8px 2px 5px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background: #eee;
                        color: #01882e;
                        cursor: pointer;
                        display: inline-block;
                        .receiptspan{
                            font-size: 12px;
                        }
                    }
                    .verifyBtn{
                        color: red !important;
                    }
                }
                .failedTxn{
                    background: #f1b2b2;
                }
            }
        }
    }
    .dialogBtn{
        padding: 0.5vw 1vw;
        font-size: 0.7vw;
        color: white;
        border-radius: 5px;
        text-transform: uppercase;
        margin-left: 1vw;
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }
}

.costHeadTarget{
    height: 50px;
    padding-left: 1vw;
    flex-direction: column;
    text-align: right;
    min-width: 100px;
    @media only screen and (max-width: 576px) {
        min-width: 1px;
        padding-left: 12px;
    }
    .target{
        //font-size: 0.7vw;
        font-size: 10px;
        @media only screen and (max-width: 576px) {
            font-size: 9px;
        }
    }
}

.projectBudget{
    color: #be1515;
}
.fundRaised{
    color: green;
}
.edaanHeader{
    color: #03c473;
}
.epujoHeader{
    color: #f08c1a;
}
.dialogFooter{
    justify-content: end;
    .donateBtn, .cancelBtn{
        background: #eee !important;
        margin-left: 1vw !important;
        font-weight: 600;
        padding: 10px 15px !important;
        .icon{
            margin-right: 0.5vw;
        }
    }
}
