

.selectedOptionClass{
  background-color: var(--primary-color-light-edaan);
  color: white;
  border-radius: inherit;
  width: 100%;
}
.navigationlink{
  cursor: pointer;
  font-size: 0.8vw;
  color: var(--primary-color-dark-edaan);
  text-decoration: underline;
}



.basicBtnStyle{
  cursor: pointer;
  border-radius: 10px ;
  letter-spacing: 1.5px ;
  text-transform: uppercase ;
  text-align: center;
  font-size: 14px;
  //centering text
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 576px) {
    font-size: 12px;
    border-radius: 5px ;
  }
}
.primaryBtnStyle{
    @extend .basicBtnStyle;
    background: var(--primary-color-light-edaan) ;
    background: #03c473 ;
    color: #fff;
    cursor: pointer;
    padding: 1em 1.5em ;
    border: none !important;
    margin: 1em 0 ;
    .icon{
      font-size: 1vw;
      margin: 0 0.3vw;
    }
    &:hover {
      opacity: 0.8;
      background: #03c473;
      color: white;
    }
}
.secondaryBtnStyle {
  @extend .basicBtnStyle;
  background: var(--secondary-color);
  color: var(--primary-color-dark-edaan);
  padding: 1em 1.5em;
  border: 1px solid var(--secondary-color);
  margin: 1em 0;
  .icon{
    font-size: 1vw;
    margin: 0 0.3vw;
  }
  &:hover {
    opacity: 0.8;
  }
}
.tertiaryBtnStyle {
  @extend .basicBtnStyle;
  background: var(--border-color);
  color: var(--primary-color-light-edaan);
  padding: 1em 1.5em;
  border: 1px solid var(--border-color);
  margin: 1em 0;
  .icon{
    font-size: 14px;
    margin: 0 0.3vw;
  }
  &:hover {
    opacity: 0.8;
  }
}
.inlineBtnStyle{
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: 600;
  color: var(--primary-color-light-edaan);
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background: transparent;
  &:hover {
    opacity: 0.8;
  }
}
.headerUnderline{
  width: 60px;
  border-top: 4px solid var(--primary-color-light-edaan);
  margin: 5px 0 12px 0;
  opacity: 1;
  @media only screen and (max-width: 768px) {
    margin: 5px 0 8px 0;
  }
}


.registrationNumber{
  font-size: 12px;
  background: #fcecbf;
  color: var(--primary-color-light-edaan);
  border: 1px solid var(--primary-color-light-edaan);
  width: fit-content;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 3px 8px;
  margin: 0 auto;
  @media only screen and (max-width: 576px) {
    font-size: 10px;
  }
}

.cardOwnerInfo{
  background: var(--primary-color-light-edaan);
}

// ********************************************inputs***********************************************
.inputWrap{
  width: 100%;
  padding: 1em 0;
  .label{
      font-size: 0.8vw;
      color: var(--primary-color-dark-edaan);
      text-transform: uppercase;
      letter-spacing: 1.3px;
      width: 100%;
      padding-bottom: 5px;
      span{
          color: red;
      }
  }
  input,textarea{
      border: 1px solid var(--secondary-color);
      padding: 1em;
      border-radius: 5px;
      font-size: 1em;
      width: 100%;
      color: var(--primary-color-light-edaan);
      &:hover{
          border: 1px solid var(--primary-color-dark-edaan);
      }
      &:active, &:focus{
          border: 1px solid var(--primary-color-light-edaan);
      }
  }
}
.categoryWrap{
  max-height: 50vh;
  overflow: auto;
  .selectionRow{
    width: 100%;
    padding: 1vw;
    font-size: 1em;
    color: var(--secondary-color);
    color: #95aac0;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    border-bottom: 1px solid #eee;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
    &:hover{
        color: var(--primary-color-dark-edaan);
        color: #01160d;
    }
    .category{
        margin-left: 10px;
        cursor: pointer;
    }
    .icon{
      color: transparent;
      margin-right: 5px;
    }
  }
  .category{
    width: fit-content;
    label{
        cursor: pointer;
        margin-left: 10px;
    }
  }
  .categorySelection{
    color: var(--primary-color-light-edaan);
    color: #03c473;
    border-left: 2px solid var(--primary-color-light-edaan);
    border-left: 2px solid #03c473;
    &:hover{
        color: var(--primary-color-light-edaan);
        color: #03c473;
    }
    .icon{
        color: var(--primary-color-light-edaan);
        color: #03c473;
    }
  }
}






// ********************************************searchBar/background***********************************************
.contentWrapper{
  background: var(--background-secondary-edaan);
  .menubar{
    height: 14%;
    justify-content: space-between;
    align-items: center;
    .searchBar{
      height: 50%;
      width: 100%;
      padding: 0 2em;
      font-size: 1em;
      letter-spacing: 1.5px;
      color: var(--primary-color-dark-edaan);
      background: var(--background-primary);
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      border: none;
      &:hover, &:active, &:focus{
        transition: all 0.5s ease;
        box-shadow: var(--box-shadow-selected);
        // border-left: 1px solid var(--border-color);
      }
      @media only screen and (max-width: 576px) {
        font-size: 12px;
        padding: 0 10px;
      }
    }
  }
}



// ********************************************navbar***********************************************
.navbar{
  background-color: var(--background-primary) !important;
  box-shadow: none !important;
  color: var(--primary-color-light-edaan) !important;
  height: 10vh;
  justify-content: space-between;
  font-size: 1.2em;
  @media only screen and (max-width: 768px) {
    height: 8vh;
  }
  .navbarBtnStyle{
      background: var(--border-color);
      color: var(--primary-color-light-edaan);
      cursor: pointer;
      padding: 0.4vw 1vw;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0 1vw;
      text-align: center;
      .icon{
          font-size: 1vw;
          margin: 0 0.3vw;
      }
      &:hover {
          opacity: 0.8;

      }
  }
  .sitename{
      font-weight: 900;
      letter-spacing: 0.1rem;
      margin-right: 2em;
  }
  .organisationName{
      font-size: 0.8em;
      letter-spacing: 1px;
      color: var(--primary-color-dark-edaan);
      text-decoration: none;
      background: linear-gradient(-90deg, rgba(3, 196, 115, 0.231372549), white);
      padding: 0 0 0 1vw;
      border-radius: 50px;
      .userType{
        font-size: 12px;
      }
      .logo{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-left: 0.5vw;
    }
  }
  .menuItem{
      font-size: 0.9em;
      font-weight: 400;
      letter-spacing: 0.2em;
      padding: 2px 5px;
      margin: 0 10px;
      cursor: pointer;
      text-transform: uppercase;
      color: var(--primary-color-dark-edaan);
      &:hover{
          background: var(--background-secondary-edaan);
      }
  }
  .selectedMenuItem{
      color: var(--primary-color-light-edaan);
  }
}

.fontColor{
  color: var(--primary-color-light-edaan) !important;
}

.cardHeader {
  -webkit-text-stroke-color: var(--primary-color-light-edaan);
}

.fontColorLight{
  color: var(--primary-color-light-edaan) !important;
}
.fontColorDarkGreen{
  color: var(--primary-color-dark-green-edaan) !important;
}
.fontColorDark{
  color: var(--primary-color-dark-edaan) !important;
}
.backgroundColorLight{
  background: var(--primary-color-light-edaan) !important;
}


//.mobileMenu {
  .bar {
    background-color: var(--primary-color-light-edaan);
  }
  .menu-bg {
    background: radial-gradient(circle, var(--primary-color-light-edaan), var(--primary-color-dark-edaan));
  }
//}
